export default function baseMeta () {
  let baseMeta = {
    title: 'Anchor',
    description: 'In a sea of digital uncertainty, we can be your anchor. Strategic web services to help grow your brand.',
    keywords: 'anchor web development agency columbus ohio javascript css scss html seo sem gtm google analytics',
    image: '/favicon.png'
  }

  if (window.storeData) baseMeta = { ...baseMeta, ...window.storeData }

  return baseMeta
}
