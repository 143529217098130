<template>
  <div class="home">
    <div class="background-wrapper">
      <vue-background-image :image-source="require('@/assets/hero.jpg')">
        <div class="home-header-content max-width-s">
          <h1 class="hero uppercase">
            In a sea of digital uncertainty,<br> we can be your anchor.
          </h1>
          <router-link class="button" to="/services">Our Services</router-link>
        </div>
      </vue-background-image>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="scss" scoped>
.background-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  .vue-background-image {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    color: $white;

    .home-header-content {
      position: absolute;
      bottom: 15vh;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 1.5em;

      @media(min-width: $bp-s) {
        bottom: 20vh;
      }

      > * {
        color: $white;
      }
    }
  }
}
</style>
