<template>
  <div id="app">
    <nav id="nav" class="max-width-s">
      <router-link to="/" class="logo"><img alt="Vue logo" src="./assets/logo.png"></router-link>

      <div class="links">
        <router-link class="featured" to="/services">Services</router-link>
        <router-link class="featured" to="/about">About</router-link>
        <router-link class="featured" to="/contact">Contact</router-link>
      </div>
    </nav>
    <router-view />
    <footer class="max-width-s" v-if="$router.history.current.path !== '/'">
      <p>© Anchor {{ currentYear }}</p>
      <router-link class="featured" to="/style-guide">Style Guide</router-link>
    </footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentYear: new Date().getFullYear()
    }
  },

  beforeMount () {
    // wait a few seconds to allow fetching all the data
    // before emitting the prerender hook
    setTimeout(() => {
      document.dispatchEvent(new Event('x-app-rendered'))
    }, 2000)
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
@import './styles/_grid.scss';
@import './styles/_elements.scss';
@import './styles/helpers.scss';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$logo-width: 4em;

#nav {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: $logo-width / 2 1fr;
  padding: 1em 1.5em;
  position: relative;
  z-index: 1;

  .logo {
    img {
      max-width: $logo-width / 2;
    }
  }

  @media(min-width: $bp-s) {
    grid-template-columns: $logo-width 1fr;
    align-items: flex-start;

    .logo {
      img {
        max-width: $logo-width;
      }
    }
  }

  .links {
    text-align: right;
    text-transform: uppercase;

    a {
      margin: 0 0 0 1em;
      padding: 0.25em;

      &:after {
        visibility: hidden;
        transition: unset;
      }

      &.router-link-exact-active:after, &:hover:after {
        visibility: visible;
      }

      @media(max-width: $bp-s - 1) {
        &:last-of-type {
          padding-right: 0;
        }
      }

      @media(min-width: $bp-xs) {
        margin: 0 1em 0;
      }

      @media(min-width: $bp-s) {
        margin: 1.5em 2em 0;
      }
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2em 1.25rem 0;
  text-align: center;
  font-size: 0.8em;
  position: relative;
  z-index: 1;

  p {
    color: $gray-x-dark;
    margin-bottom: 0;
  }
}
</style>
